@font-face {
  font-family: Felix Titling;
  src: url("Felix_Titling.a7f626ba.woff2") format("woff2"), url("Felix_Titling.e7c779ee.woff") format("woff"), url("Felix_Titling.81e750b6.TTF") format("truetype");
}

:root {
  --color-primary: #203864;
  --color-primary-mid: #2f5597;
  --color-primary-light: #b4c7e7;
  --color-secondary: #f7ba39;
  --color-grey-mid: #646464;
  --color-grey-light: #f8f9fa;
  --color-grey-dark: #343a40;
}

* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  color: #444;
  background-color: #f8f9fa;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.9;
}

h1 {
  color: var(--color-primary);
  font-family: Felix Titling, sans-serif;
  font-size: 5rem;
}

h2 {
  text-transform: uppercase;
  color: var(--color-primary-mid);
  text-align: center;
  margin: 2rem 0;
  font-size: 3rem;
  font-weight: 500;
}

h3 {
  text-transform: uppercase;
  color: var(--color-primary-mid);
  margin: 1rem 0;
  font-size: 2rem;
  font-weight: 400;
}

footer {
  background-color: var(--color-primary);
  padding: 3rem;
}

.info-text {
  margin-bottom: 1rem;
}

.btn {
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  padding: 1.5rem 3rem;
  font-family: inherit;
  font-size: 1.7rem;
  font-weight: 500;
  transition: all .2s;
  display: inline-block;
}

.btn--white {
  color: var(--color-primary);
  background-color: #fff;
}

.btn--white:hover {
  background-color: var(--color-secondary);
  color: #fff;
}

.btn--primary {
  background-color: var(--color-primary);
  color: #fff;
}

.btn--primary:hover {
  background-color: var(--color-primary-mid);
}

.header {
  height: 100vh;
  background-image: linear-gradient(#2f559773, #2f559773), url("Hero_Image_Drop.bb8a242f.jpg");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem;
  display: flex;
}

@supports (-webkit-touch-callout: none) {
  .header {
    background-attachment: scroll;
  }
}

@supports not (-webkit-touch-callout: none) {
  .header {
    background-attachment: fixed;
  }
}

.header--page {
  padding: 0 3rem;
}

.margin-bottom-medium {
  margin-bottom: 2rem;
}

.margin-bottom-big {
  margin-bottom: 3rem;
}

.nav-bar {
  height: 8rem;
  width: 100%;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.nav-bar--page {
  background-color: #fff;
}

.nav__logo {
  width: 20rem;
  height: auto;
  display: none;
}

.nav__logo--page {
  display: block;
}

.nav__links {
  align-items: center;
  gap: 4rem;
  list-style: none;
  display: flex;
}

.nav__link:link, .nav__link:visited {
  color: var(--color-grey-light);
  font-size: 1.7rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.nav__link--page:link, .nav__link--page:visited {
  color: var(--color-primary);
}

.nav__link:hover, .nav__link:active {
  border-bottom: 2px solid var(--color-secondary);
}

.nav-bar.sticky {
  border-bottom: 1px solid var(--color-grey-light);
  background-color: #fff;
  padding: 0 4rem;
  position: fixed;
}

.nav__link.sticky {
  color: var(--color-primary);
}

.nav-resp__icon.sticky, .nav-resp__icon.sticky:before, .nav-resp__icon.sticky:after {
  background-color: var(--color-primary);
}

.nav-resp, .nav-resp__checkbox {
  display: none;
}

.nav-resp__btn {
  height: 4rem;
  width: 4rem;
  text-align: center;
  cursor: pointer;
}

.nav-resp__icon {
  margin-top: 2rem;
  position: relative;
}

.nav-resp__icon, .nav-resp__icon:before, .nav-resp__icon:after {
  background-color: var(--color-grey-light);
  height: 2px;
  width: 3rem;
  border: none;
  transition: all .2s;
  display: inline-block;
}

.nav-resp__icon:before, .nav-resp__icon:after {
  content: "";
  position: absolute;
  left: 0;
}

.nav-resp__icon:before {
  top: -.8rem;
}

.nav-resp__icon:after {
  top: .8rem;
}

.nav-resp__checkbox:checked + .nav-resp__btn .nav-resp__icon {
  background-color: #0000;
}

.nav-resp__checkbox:checked + .nav-resp__btn .nav-resp__icon:before {
  background-color: var(--color-primary);
  top: 0;
  transform: rotate(135deg);
}

.nav-resp__checkbox:checked + .nav-resp__btn .nav-resp__icon:after {
  background-color: var(--color-primary);
  top: 0;
  transform: rotate(-135deg);
}

.links__container.responsive {
  height: 100vh;
  border: 1px solid var(--color-grey-light);
  background-color: #fff;
  padding: 6rem 4rem 0;
  position: absolute;
  top: 0;
  right: 0;
}

.nav__link.responsive {
  color: var(--color-primary);
  margin: 3rem 2rem;
  font-size: 2rem;
}

.congress {
  width: 100%;
  height: auto;
  background-color: var(--color-grey-light);
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.congress__img {
  width: 10rem;
}

.congress__text {
  font-size: 4rem;
}

.hero__info {
  max-width: 115rem;
  flex-direction: column;
  align-items: center;
  margin: auto;
  display: flex;
}

.hero__info-header {
  gap: 3rem;
  display: flex;
}

.hero__info-img {
  width: 20rem;
  height: auto;
  align-self: flex-start;
}

.hero__info-title {
  text-transform: uppercase;
  color: #fff;
  font-family: Felix Titling, sans-serif;
  font-size: 10rem;
}

.hero__info-subtitle {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  margin-bottom: 3rem;
  font-family: Felix Titling, sans-serif;
  font-size: 4rem;
}

.section {
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  display: flex;
}

.section__title-container {
  text-align: center;
}

.section__title {
  text-transform: uppercase;
  color: var(--color-primary-mid);
  margin: 3rem 0;
  font-size: 3.5rem;
  font-weight: 400;
}

.section__title:after {
  height: 1px;
  width: 10rem;
  background-color: var(--color-secondary);
  content: "";
  border: none;
  margin: 0 auto;
  display: block;
  transform: translateY(.1rem);
}

.section__subtitle {
  text-transform: uppercase;
  color: var(--color-primary-mid);
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 400;
}

.btn--text:link, .btn--text:visited {
  color: var(--color-primary);
  cursor: pointer;
  background: none;
  border-bottom: 2px solid;
  font-family: inherit;
  font-size: 1.7rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.btn--text:hover, .btn--text:active {
  color: var(--color-primary-mid);
}

.section--serve {
  background-color: #fff;
}

.section__info-goals {
  margin-bottom: 6rem;
  padding-left: 3rem;
}

.section__info-container {
  max-width: 100rem;
  text-align: justify;
  text-justify: inter-word;
}

.section__links {
  justify-content: space-between;
  margin: 4rem 0;
  display: flex;
}

.section__link {
  width: 30%;
  height: auto;
  background-color: var(--color-secondary);
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem;
  display: flex;
  position: relative;
}

.section__link-icon {
  color: var(--color-primary);
  font-size: 4rem;
}

.section__link-info {
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.section__link-text {
  color: var(--color-primary);
  margin-bottom: 1rem;
}

.section--organisation {
  background-color: var(--color-grey-light);
}

.section__options-container {
  -moz-column-gap: 6rem;
  grid-template-columns: 1fr 1fr;
  gap: 3rem 6rem;
  margin-bottom: 6rem;
  padding: 2rem 3rem;
  display: grid;
}

.section__option {
  text-align: center;
  cursor: default;
  background-color: #fff;
  border-radius: 1rem;
  padding: 3rem;
  transition: all .5s;
}

.info__img {
  width: 100%;
  height: auto;
}

.section--locations {
  background-color: #fff;
  padding-bottom: 10rem;
}

.locations-container {
  width: 80%;
  height: 50rem;
  z-index: 1;
  border-radius: 10px;
  grid-template-columns: 1fr;
  margin: 0 auto;
  display: grid;
  overflow: hidden;
}

.locations-list {
  background-color: var(--color-grey-mid);
}

.map-container, .section--news {
  background-color: var(--color-grey-light);
}

.slider {
  max-width: 100rem;
  width: 80%;
  height: 45rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform 1s;
  display: flex;
  position: absolute;
  top: 0;
}

.news-piece {
  width: 65%;
  position: relative;
}

.news-piece__number {
  text-align: center;
  color: var(--color-grey-mid);
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.news-piece__text {
  margin-bottom: 1.2rem;
  font-size: 1.7rem;
}

.news-piece__source {
  color: var(--color-grey-mid);
  margin-bottom: 1.3rem;
  font-size: 1.4rem;
}

.news-piece__link-container {
  justify-content: center;
  display: flex;
}

.news-piece__link:link, .news-piece__link:visited {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 100px;
  padding: 1.5rem 3rem;
  text-decoration: none;
  transition: all .2s;
}

.news-piece__link:hover, .news-piece__link:active {
  background-color: var(--color-primary-mid);
}

.section--join {
  background-color: #fff;
}

.benefits {
  width: 80%;
  -moz-column-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem 3rem;
  display: grid;
}

.benefits-block {
  background-color: #fff;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.block-1 {
  grid-area: span 2 / 1;
}

.block-2 {
  grid-area: span 2 / 2;
}

.block-3 {
  grid-area: 1 / 3;
}

.block-4 {
  grid-area: 2 / 3;
}

.benefits-block__title {
  text-transform: uppercase;
  color: var(--color-primary-mid);
  text-align: center;
  font-size: 2.2rem;
}

.benefits-block__icon {
  margin-bottom: .5rem;
  font-size: 3rem;
}

.benefits-block__benefit {
  background-color: var(--color-grey-light);
  text-align: center;
  cursor: default;
  color: var(--color-primary);
  border-radius: 5px;
  padding: 2rem;
  line-height: 1.2;
  transition: all .5s;
}

.benefits-block__benefit:hover {
  border: solid 1px var(--color-primary);
  transform: scale(1.05);
}

.requirements {
  margin-top: 3rem;
}

.requirements-container {
  background-color: var(--color-primary-light);
  width: 75%;
  border-radius: 5px;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem auto;
  padding: 3rem 5rem;
  display: flex;
}

.requirement {
  gap: 2.5rem;
  display: flex;
}

.requirement__id {
  height: 4rem;
  width: 4rem;
  background-color: var(--color-primary-mid);
  color: #fff;
  border-radius: 100%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
}

.requirement__desc {
  cursor: default;
  justify-content: left;
  align-items: center;
  line-height: 1.2;
  transition: all .5s;
  display: flex;
}

.requirement__desc:hover {
  color: var(--color-primary);
}

.btn--admission {
  align-self: center;
  margin: 2rem 0;
}

.help-container {
  color: var(--color-grey-mid);
  align-items: flex-start;
  gap: 1rem;
  margin: 0 auto;
  display: flex;
}

.help-text {
  font-size: 1.3rem;
  line-height: 1.2;
}

.help-link:link, .help-link:visited {
  color: var(--color-grey-dark);
  text-decoration: none;
  transition: all .2s;
}

.help-link:hover, .help-link:active {
  border-bottom: 1px solid var(--color-grey-dark);
  color: var(--color-grey-dark);
}

.footer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.footer__info {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__logo {
  height: 5rem;
}

.footer__logo img {
  max-height: 100%;
  display: block;
}

.footer__social {
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  display: flex;
}

.footer__social-text {
  color: #fff;
  font-size: 1.3rem;
}

.footer__social-item, .footer__social-link {
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer__social-link:link, .footer__social-link:visited {
  color: #fff;
  font-size: 3.5rem;
  text-decoration: none;
  transition: all .3s;
}

.footer__social-link:hover, .footer__social-link:active {
  color: var(--color-primary-light);
}

.footer__legal {
  width: 70%;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  display: flex;
}

.footer__legal-link:link, .footer__legal-link:visited {
  color: #fff;
  font-size: 1.3rem;
  text-decoration: none;
  transition: all .3s;
}

.footer__legal-link:hover, .footer__legal-link:active {
  border-bottom: 1.5px solid #fff;
}

.copyright-notice {
  color: #fff;
  font-size: 1rem;
}

@media only screen and (width <= 800px) {
  html {
    font-size: 50%;
  }

  .nav-resp {
    display: block;
  }

  .links__container {
    display: none;
  }

  .nav__links {
    list-style: none;
    display: block;
  }

  .nav__link:link, .nav__link:visited {
    color: var(--color-primary);
  }

  .nav-bar {
    padding: 0 1rem;
  }

  .section__links {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .section__link {
    width: 80%;
  }

  .section__options-container {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    display: flex;
  }

  .locations-container {
    width: 95%;
  }

  .benefits {
    width: 80%;
    flex-direction: column;
    gap: 1rem;
    display: flex;
  }

  .requirements-container {
    width: 90%;
  }
}

@media only screen and (width <= 480px) {
  h2 {
    font-size: 2.5rem;
    line-height: 140%;
  }

  .hero__info-header {
    gap: 3rem;
    display: block;
  }

  .hero__info-img {
    width: 15rem;
  }

  .hero__info-title {
    font-size: 8rem;
  }

  .hero__info-subtitle {
    font-size: 3rem;
  }

  .section {
    padding: 2rem;
  }

  .section__title {
    text-align: center;
    font-size: 3rem;
    line-height: 150%;
  }

  .locations-container, .requirements-container {
    width: 100%;
  }

  .footer__info {
    flex-direction: column;
    gap: 2rem;
  }

  .footer__legal {
    flex-direction: column;
    gap: .5rem;
  }

  .congress {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    padding: 1.5rem;
  }

  .congress__text {
    text-align: center;
  }

  .congress__container-img {
    display: none;
  }
}

/*# sourceMappingURL=index.1439c9ba.css.map */
